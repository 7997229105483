import {defineStore} from 'pinia';
import storage from '~/utils/storage';
import {toRaw} from 'vue';
import isEmpty from 'lodash/isEmpty'

export const UserStore = defineStore('user-store', {
    state: () => ({

    }),
    actions: {
        async jwt(){
            return "Visitor"
        }
    },
});
