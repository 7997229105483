import axios from 'axios';

axios.interceptors.request.use(
    async (config) => {
        if (config.timeout === 0) {
            config.timeout = 20000; // 默认20秒请求超时
        }
        return config;
    },
    (error) => Promise.reject(error),
);

axios.interceptors.response.use(
    (response) => {
        const { code } = response.data;

        return response;
    },
    async (error) => {
        if(error.response.status === 401){
            notify.err(error.response.data.Code);
            return Promise.reject(error);
        }

        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            // 禁用重试
            originalRequest._retry = false;

            return Promise.resolve(error.response.data);
        }

        return Promise.reject(error);
    },
);


export default axios;
