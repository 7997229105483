import { default as cashier_45alipay_45storekjB786PufBMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/alipay/cashier-alipay-store.js?macro=true";
import { default as mobile5yuxHMeD0uMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/alipay/mobile.vue?macro=true";
import { default as pageO0bYa4DQHLMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/alipay/page.vue?macro=true";
import { default as pc0nNihG1ikvMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/alipay/pc.vue?macro=true";
import { default as page29yv9jGpR3Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/page.vue?macro=true";
import { default as cashier_45usdt_45store91aQGcQdtWMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/usdt/cashier-usdt-store.js?macro=true";
import { default as fee_45modaliAaLEScGOZMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/usdt/fee-modal.vue?macro=true";
import { default as mobileAMW7uldF4nMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/usdt/mobile.vue?macro=true";
import { default as pageQTQWq7TomeMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/usdt/page.vue?macro=true";
import { default as pcIx9eJVqlSWMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/usdt/pc.vue?macro=true";
import { default as indexqjIiz2MdIAMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/index.vue?macro=true";
import { default as detailxzAKC8PcKSMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/tips/detail.vue?macro=true";
import { default as tips_45storeYu5yu1nV9rMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/tips/tips-store.js?macro=true";
export default [
  {
    name: "cashier-alipay-cashier-alipay-store",
    path: "/cashier/alipay/cashier-alipay-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/alipay/cashier-alipay-store.js")
  },
  {
    name: "cashier-alipay-mobile",
    path: "/cashier/alipay/mobile",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/alipay/mobile.vue")
  },
  {
    name: "cashier-alipay-page",
    path: "/cashier/alipay/page",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/alipay/page.vue")
  },
  {
    name: "cashier-alipay-pc",
    path: "/cashier/alipay/pc",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/alipay/pc.vue")
  },
  {
    name: "cashier-page",
    path: "/cashier/page",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/page.vue")
  },
  {
    name: "cashier-usdt-cashier-usdt-store",
    path: "/cashier/usdt/cashier-usdt-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/usdt/cashier-usdt-store.js")
  },
  {
    name: "cashier-usdt-fee-modal",
    path: "/cashier/usdt/fee-modal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/usdt/fee-modal.vue")
  },
  {
    name: "cashier-usdt-mobile",
    path: "/cashier/usdt/mobile",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/usdt/mobile.vue")
  },
  {
    name: "cashier-usdt-page",
    path: "/cashier/usdt/page",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/usdt/page.vue")
  },
  {
    name: "cashier-usdt-pc",
    path: "/cashier/usdt/pc",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/cashier/usdt/pc.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexqjIiz2MdIAMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/index.vue")
  },
  {
    name: "tips-detail",
    path: "/tips/detail",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/tips/detail.vue")
  },
  {
    name: "tips-tips-store",
    path: "/tips/tips-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/public/pages/tips/tips-store.js")
  }
]