// 运行模式
import {mbrRechargeUsdtQuotaIdStatusDesc} from "~/consts/desc";

export const ENV_DEV = "dev";
export const ENV_TEST = "test";
export const ENV_PRO = "pro";

// 语言
export const LANG_ZH_CN = 'zh_cn';//简体中文
export const LANG_ZH_TC = 'zh_tc';//繁体中文
export const LANG_EN = 'en';//英文

// 富文本编辑器 图片参数占位符
export const EDITOR_IMAGE_URL_JWT_PLACEHOLDER = '@@jwt@@';
export const EDITOR_IMAGE_URL_DOMAIN_PLACEHOLDER = '@@domain@@';

// 图片上传是否是 编辑器模式
export const IMAGE_UPLOAD_EDITOR_MODE_N = 1; // 否
export const IMAGE_UPLOAD_EDITOR_MODE_Y = 2; // 是

//帮助提示信息类型
export const HELP_TIP_TYPE_TIP = 1 //tooltip
export const HELP_TIP_TYPE_LINK = 2 //markdown

//提示词平台
export const HELP_TIP_PLATFORM_PC = 1// PC
export const HELP_TIP_PLATFORM_APP = 2//APP


//提现货币类型
export const WITHDRAW_CURRENCY_TYPE_CNY = 1
export const WITHDRAW_CURRENCY_TYPE_USDT = 2

//提现类型
export const WITHDRAW_TYPE_ALIPAY = 1
export const WITHDRAW_TYPE_WECHAT = 2
export const WITHDRAW_TYPE_BANK = 3
export const WITHDRAW_TYPE_ERC20 = 4
export const WITHDRAW_TYPE_TRC20 = 5

//错误码
export const SUCCESS_CODE = "success";

// ***** 支付方式 *****

//自建-支付宝个码
export const PAYMENT_METHOD_CODE_SELF_ALIPAY_PER_QRCODE = "self_alipay_per_qrcode";
export const PAYMENT_METHOD_CODE_SELF_USDT = "usdt";

//通用下拉列表 全部选项
export const SELECT_COMMON_ALL = 0;
//链类型
export const LINK_TYPE_ERC20 = 1;
export const LINK_TYPE_TRC20 = 2;
export const LINK_TYPE_OMNI = 3;

//默认每页数据条数
export const DEFAULT_PAGE_SIZE = 20;
export const MAX_PAGE_SIZE = 100; //服务端支持的每页最大数据条数

// 资质状态
export const QUAL_ALIPAY_PER_QR_CODE_STATUS_NORMAL = 1
export const QUAL_ALIPAY_PER_QR_CODE_STATUS_MEMBER_DISABLED = 2
export const QUAL_ALIPAY_PER_QR_CODE_STATUS_ADMIN_DISABLED = 3
export const QUAL_ALIPAY_PER_QR_CODE_STATUS_NOT_REVIEWED = 4
export const QUAL_ALIPAY_PER_QR_CODE_STATUS_REJECT = 5

// 资质收款状态
export const QUAL_ALIPAY_PER_OR_CODE_ALLOW_RECEIPT = 1
export const QUAL_ALIPAY_PER_OR_CODE_DISABLED_RECEIPT = 2

// 商户支付方式策略状态
export const MERCH_POLICY_ENABLE = 1
export const MERCH_POLICY_DISABLE = 2

//汇率平台
export const RATE_PLATFORM_OKX = 1
export const RATE_PLATFORM_GATE = 2

//平台收U钱包状态
export const PLATFORM_RECEIVE_USDT_WALLET_STATUS_ENABLE = 1 //启用
export const PLATFORM_RECEIVE_USDT_WALLET_STATUS_DISABLE = 2 //禁用

//会员充U配额状态
export const MBR_RECHARGE_USDT_QUOTA_ID_STATUS_ENABLE = 1 //启用
export const MBR_RECHARGE_USDT_QUOTA_ID_STATUS_DISABLE = 2 //禁用

//会员USDT充值申请状态
export const MBR_USDT_BALANCE_RECHARGE_APPLY_STATUS_PENDING = 1 //等待审核
export const MBR_USDT_BALANCE_RECHARGE_APPLY_STATUS_PASSED = 2 //审核通过
export const MBR_USDT_BALANCE_RECHARGE_APPLY_STATUS_REFUSED = 3 //审核拒绝
export const MBR_USDT_BALANCE_RECHARGE_APPLY_STATUS_MBR_CANCEL = 4 //会员取消
export const MBR_USDT_BALANCE_RECHARGE_APPLY_STATUS_ADMIN_CANCEL = 5 //管理员取消

//会员USDT提现申请状态
export const MBR_USDT_BALANCE_WITHDRAWALS_APPLY_STATUS_PENDING = 1 //等待审核
export const MBR_USDT_BALANCE_WITHDRAWALS_APPLY_STATUS_PASSED = 2 //审核通过
export const MBR_USDT_BALANCE_WITHDRAWALS_APPLY_STATUS_REFUSED = 3 //审核拒绝
export const MBR_USDT_BALANCE_WITHDRAWALS_APPLY_STATUS_MBR_CANCEL = 4 //会员取消
export const MBR_USDT_BALANCE_WITHDRAWALS_APPLY_STATUS_ADMIN_CANCEL = 5 //管理员取消

//会员USDT佣金提现到余额申请状态
export const MBR_USDT_COMMISSION_WITHDRAWALS_TO_BALANCE_APPLY_STATUS_PENDING = 1 //等待审核
export const MBR_USDT_COMMISSION_WITHDRAWALS_TO_BALANCE_APPLY_STATUS_PASSED = 2 //审核通过
export const MBR_USDT_COMMISSION_WITHDRAWALS_TO_BALANCE_APPLY_STATUS_REFUSED = 3 //审核拒绝
export const MBR_USDT_COMMISSION_WITHDRAWALS_TO_BALANCE_APPLY_STATUS_MBR_CANCEL = 4 //会员取消
export const MBR_USDT_COMMISSION_WITHDRAWALS_TO_BALANCE_APPLY_STATUS_ADMIN_CANCEL = 5 //管理员取消

//会员USDT内部转账申请状态
export const MBR_USDT_TRANS_TO_MBR_APPLY_STATUS_PENDING = 1 //等待审核
export const MBR_USDT_TRANS_TO_MBR_APPLY_STATUS_PASSED = 2 //审核通过
export const MBR_USDT_TRANS_TO_MBR_APPLY_STATUS_REFUSED = 3 //审核拒绝
export const MBR_USDT_TRANS_TO_MBR_APPLY_STATUS_MBR_CANCEL = 4 //会员取消
export const MBR_USDT_TRANS_TO_MBR_APPLY_STATUS_ADMIN_CANCEL = 5 //管理员取消

//会员状态
export const MBR_STATUS_ENABLE = 1 //账号启用
export const MBR_STATUS_DISABLE = 2 //账号禁用


//派单状态
export const MEMBER_DISPATCH_ORDER_CNY_STATUS_SUCCESS = 1  //订单已完成
export const MEMBER_DISPATCH_ORDER_CNY_STATUS_CREATED = 2  //订单已创建
export const MEMBER_DISPATCH_ORDER_CNY_STATUS_ASSIGNED = 3  //订单已派单给会员
export const MEMBER_DISPATCH_ORDER_CNY_STATUS_MANUAL_REVIEW = 4 //订单手动审核中
export const MEMBER_DISPATCH_ORDER_CNY_STATUS_MANUAL_REVIEW_REJECT = 5 //订单手动拒绝
export const MEMBER_DISPATCH_ORDER_CNY_TIMEOUT = 6

export const MEMBER_DISPATCH_ORDER_USDT_STATUS_SUCCESS = 1  //订单已完成
export const MEMBER_DISPATCH_ORDER_USDT_STATUS_CREATED = 2  //订单已创建
export const MEMBER_DISPATCH_ORDER_USDT_STATUS_USER_PAID = 3  //用户已充值
export const MEMBER_DISPATCH_ORDER_USDT_STATUS_ERROR = 4  //订单异常
export const MEMBER_DISPATCH_ORDER_USDT_STATUS_CLOSE = 5  //订单关闭
export const MEMBER_DISPATCH_ORDER_USDT_STATUS_TIMEOUT = 6  //订单超时

//商户状态
export const MERCH_STATUS_ENABLE = 1 //开启
export const MERCH_STATUS_DISABLE = 2 //禁用

//商户代理状态
export const MERCH_AGENT_STATUS_ENABLE = 1 //开启
export const MERCH_AGENT_STATUS_DISABLE = 2 //禁用

// 用户类型
export const USER_TYPE_MEMBER = 1; //会员
export const USER_TYPE_MEMBER_AGENT = 2; //会员代理
export const USER_TYPE_MERCHANT = 3; //商户
export const USER_TYPE_MERCHANT_AGENT = 4; //商户代理

//im
export const IM_TYPE_TEXT = 1;//文字消息
export const IM_TYPE_IMAGE = 2;//图片消息

//cny代付状态
export const MEMBER_PAYMENT_ORDER_CNY_FINISHED = 1; //代付已完成
export const MEMBER_PAYMENT_ORDER_CNY_WAIT_FOR_AUDIT = 2;//代付申请等待后台审核
export const MEMBER_PAYMENT_ORDER_CNY_MERCH_APPLY_AUDIT_PASS = 3;//代付申请后台审核通过
export const MEMBER_PAYMENT_ORDER_CNY_MERCH_APPLY_AUDIT_REJECT = 4;//代付审核审核后台拒绝
export const MEMBER_PAYMENT_ORDER_CNY_MEMBER_START_PAY = 5; //会员抢单代付成功
export const MEMBER_PAYMENT_ORDER_CNY_MEMBER_UPLOAD_RECEIPT = 6;//会员上传代付支付凭证
export const MEMBER_PAYMENT_ORDER_CNY_UPLOAD_RECEIPT_REJECT = 7;//会员上传代付支付凭证拒绝
export const MEMBER_PAYMENT_ORDER_CNY_MERCH_CANCEL = 8;//商户取消
export const MEMBER_PAYMENT_ORDER_CNY_ADMIN_REJECT = 9;//支付运营商拒绝

//会员cny代付状态
export const MBR_PB_CNY_DISABLE = 1;
export const MBR_PB_CNY_ENABLE = 2;

//usdt代付状态

export const MEMBER_PAYMENT_ORDER_USDT_FINISHED = 1  //订单完成
export const MEMBER_PAYMENT_ORDER_USDT_WAIT_FOR_AUDIT = 2        //商户代付申请待审核
export const MEMBER_PAYMENT_ORDER_USDT_MERCH_APPLY_AUDIT_PASS = 3//商户代付申请审核通过
export const MEMBER_PAYMENT_ORDER_USDT_MERCH_APPLY_AUDIT_REJECT = 4//商户代付申请审核拒绝
export const MEMBER_PAYMENT_ORDER_USDT_MERCH_CANCEL = 5//商户取消代付

export const MEMBER_PAYMENT_ORDER_TYPE_CUSTOM = 1; //会员抢单
export const MEMBER_PAYMENT_ORDER_TYPE_SELF_PLATFORM = 2;//平台代付

export const MEMBER_PAYMENT_ORDER_PAY_TYPE_ALIPAY = 1;//支付宝
export const MEMBER_PAYMENT_ORDER_PAY_TYPE_BANK = 2;//银行卡


//usdt 代付链类型
export const PB_TYPE_ERC20 = 1
export const PB_TYPE_TRC20 = 2


// 商户派单状态
export const MERCH_DO_ENABLE = 1;
export const MERCH_DO_DISABLE = 2;

//商户代付状态
export const MERCH_PB_ENABLE = 1;
export const MERCH_PB_DISABLE = 2;

export const APP_PB_IS_SHOW_Y = 1;
export const APP_PB_IS_SHOW_N = 2;

//站内信状态
export const STATION_LETTER_IS_SHOW_Y = 1;
export const STATION_LETTER_IS_SHOW_N = 2;

//RBAC
// 是否超级管理员
export const IS_SUPER_ADMIN_N = 1;
export const IS_SUPER_ADMIN_Y = 2;


//商户客户
export const MERCH_CUSTOMERS_IN_BLACK_LIST_Y = 1;
export const MERCH_CUSTOMERS_IN_BLACK_LIST_N = 2;

export const MERCH_CUSTOMERS_IN_WHITE_LIST_Y = 1;
export const MERCH_CUSTOMERS_IN_WHITE_LIST_N = 2;

// 平台代码
export const PLATFORM_SUPER_BACKEND = 1
export const PLATFORM_MERCHANT_BACKEND = 2
export const PLATFORM_MERCHANT_AGENT_BACKEND = 3
export const PLATFORM_MEMBER_BACKEND = 4
export const PLATFORM_ADMIN_BACKEND = 5
export const PLATFORM_PUBLIC = 6

//是否是补单
export const DO_CNY_IS_PATCH_ORDER_N = 1; //否
export const DO_CNY_IS_PATCH_ORDER_Y = 2; //是


export const DO_USDT_IS_PATCH_ORDER_N = 1; //否
export const DO_USDT_IS_PATCH_ORDER_Y = 2; //是


// 币种
export const CURRENCY_TAG_CNY = "CNY"
export const CURRENCY_TAG_USDT = "USDT"

//CNY派单 商户补单状态
export const MBR_DO_CNY_MERCH_PATCH_APPLY_STATUS_DEFAULT = 1; //默认
export const MBR_DO_CNY_MERCH_PATCH_APPLY_STATUS_MERCH_CANCEL = 2; //取消
export const MBR_DO_CNY_MERCH_PATCH_APPLY_STATUS_PASS = 3; //审核通过
export const MBR_DO_CNY_MERCH_PATCH_APPLY_STATUS_REFUSE = 4; //审核拒绝

//CNY派单 待匹配状态
export const MBR_DO_CNY_TO_BE_MATCHED_STATUS_MATCHED = 1; //匹配完成
export const MBR_DO_CNY_TO_BE_MATCHED_STATUS_REFUSE = 2; //拒绝匹配
export const MBR_DO_CNY_TO_BE_MATCHED_STATUS_UNHANDLE = 3; //未匹配

//回调任务状态
export const CALL_BACK_TASK_UNFINISHED = 1; //未完成
export const CALL_BACK_TASK_FINISHED = 2; //已完成

//回调任务订单类型
export const CALL_BACK_TASK_DO_CNY_CREATED = 1
export const CALL_BACK_TASK_DO_CNY_SETTLEMENT = 2
export const CALL_BACK_TASK_DO_USDT_CREATED = 3
export const CALL_BACK_TASK_DO_USDT_SETTLEMENT = 4
export const CALL_BACK_TASK_PB_CNY_CREATED = 5
export const CALL_BACK_TASK_PB_CNY_SETTLEMENT = 6
export const CALL_BACK_TASK_PB_USDT_CREATED = 7
export const CALL_BACK_TASK_PB_USDT_SETTLEMENT = 8

//平台收U钱包状态
export const PLATFORM_USDT_WALLET_STATUS_ENABLE = 1 //启用
export const PLATFORM_USDT_WALLET_STATUS_DISABLE = 2 //禁用

//是否为 默认平台收U钱包
export const PLATFORM_USDT_DEFAULT_RECEIVE_USDT_N = 1 //默认收usdt 否
export const PLATFORM_USDT_DEFAULT_RECEIVE_USDT_Y = 2 //默认收usdt 是

//平台收U钱包 交易所
export const PLATFORM_USDT_WALLET_EXCHANGE_BITPIE = 1 //bitpie


//账单 金额 增加/减少
export const BILL_ADD_MONEY = 1
export const BILL_SUB_MONEY = 2
