import {defineStore} from 'pinia';
import storage from "~/utils/storage";
import notify from "~/utils/notify";
import axios from "axios";
import {SUCCESS_CODE} from "~/consts/const";
import find from 'lodash/find';
import {EnvStore} from "~/common-store/env-store";

//todo 定时轮训拉取平台汇率信息
export const PlatformExchangeRateStore = defineStore('platform-exchange-rate-store', {
    state: () => ({
        defaultDecimalNumber: 4,//默认精度
        exchangeRateList: [],
        fetchExchangeRateListIng: false,
    }),
    actions: {
        fetchExchangeRateList() {
            if (this.fetchExchangeRateListIng) {
                return
            }
            this.fetchExchangeRateListIng = true

            const conf = EnvStore().config;

            axios({
                method: 'get',
                url: `${conf.ServerHost}/api/v1/platform-rate-exchange/list`,
            }).then((resp) => {
                const {Code, Data} = resp.data;
                if (Code != SUCCESS_CODE) {
                    notify.err('汇率信息异常')
                    return
                }

                this.exchangeRateList = Data;

            }).catch((err) => {
                notify.err('汇率信息异常')
                console.log('/api/v1/platform-rate-exchange/list', err)
            }).finally(() => {
                this.fetchExchangeRateListIng = false
            });
        },
        pairDecimal(pairName) {
            const pairNameUpper = pairName.toUpperCase()
            if (this.fetchCurrencyListIng) {
                return this.defaultDecimalNumber
            }
            return find(this.exchangeRateList, {PairName: pairNameUpper})?.Decimal || this.defaultDecimalNumber
        }
    }
});
